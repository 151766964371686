import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://8b29c47ba69bcf85e8b4a326dcdb792e@o4506492094251008.ingest.sentry.io/4506492121776128",
  enabled: process.env.NODE_ENV === "production",
  // Capturing 20% of transactions for performance monitoring.
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0,
  // Capturing all sessions that resulted in an error
  replaysOnErrorSampleRate: 1.0,
});
